var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Dialog',{attrs:{"dialog":_vm.dialog,"dialog-width":900},on:{"close":function($event){return _vm.$emit('close', true)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Create New Product ")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"inventoryForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateOrCreate.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"name"}},[_vm._v("Serial Number")]),_c('TextInput',{class:{
								required: !_vm.new_product.serial_number,
							},attrs:{"hide-details":"","hide-top-margin":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"serial-number","placeholder":"Serial Number","rules":[
								_vm.vrules.required(_vm.new_product.serial_number, 'Serial Number'),
								_vm.serialNumberError ? false : true,
							]},on:{"change":function($event){return _vm.validateSerialNumber(
									_vm.new_product.serial_number,
									_vm.new_product.product_type == 'piping' ? 'piping' : 'other-items'
								)}},model:{value:(_vm.new_product.serial_number),callback:function ($$v) {_vm.$set(_vm.new_product, "serial_number", $$v)},expression:"new_product.serial_number"}}),(_vm.serialNumberError)?_c('span',{staticClass:"red--text text--darken-1 font-small"},[_vm._v(_vm._s(_vm.serialNumberError))]):_vm._e()],1),_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"product-name"}},[_vm._v(" Name ")]),_c('TextInput',{class:{
								required: !_vm.new_product.name,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"product-name","placeholder":"Name","rules":[_vm.vrules.required(_vm.new_product.name, 'Name')]},model:{value:(_vm.new_product.name),callback:function ($$v) {_vm.$set(_vm.new_product, "name", $$v)},expression:"new_product.name"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"group"}},[_vm._v(" Category ")]),_c('AutoCompleteInput',{class:{
								required: !_vm.new_product.group,
							},attrs:{"hide-top-margin":"","hide-details":"","items":_vm.groupList,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"group","rules":[_vm.vrules.required(_vm.new_product.group, 'Category')],"placeholder":"Category","append-outer-icon":"mdi-cog"},on:{"click:append-outer":function($event){_vm.manageCategoryDialog = true}},model:{value:(_vm.new_product.group),callback:function ($$v) {_vm.$set(_vm.new_product, "group", $$v)},expression:"new_product.group"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"supplier"}},[_vm._v(" Supplier ")]),_c('AutoCompleteInput',{class:{
								required: !_vm.supplier_id,
							},attrs:{"hide-top-margin":"","hide-details":"","items":_vm.vendorList,"disabled":"","loading":_vm.pageLoading,"id":"supplier","rules":[_vm.vrules.required(_vm.supplier_id, 'Supplier')],"placeholder":"Supplier"},model:{value:(_vm.supplier_id),callback:function ($$v) {_vm.supplier_id=$$v},expression:"supplier_id"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"supplier-code"}},[_vm._v(" Supplier Code ")]),_c('TextInput',{attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"supplier-code","placeholder":"Supplier Code"},model:{value:(_vm.supplier_code),callback:function ($$v) {_vm.supplier_code=$$v},expression:"supplier_code"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"supplier-price"}},[_vm._v(" Purchasing Price ")]),_c('PriceInput',{class:{
								required: !_vm.supplier_price,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"supplier-price","rules":[_vm.vrules.required(_vm.supplier_price, 'Purchasing Price')],"placeholder":"Supplier Price"},model:{value:(_vm.supplier_price),callback:function ($$v) {_vm.supplier_price=$$v},expression:"supplier_price"}})],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.pageLoading,"depressed":"","tile":""},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Close")]),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"depressed":"","color":"blue darken-4","tile":""},on:{"click":function($event){return _vm.updateOrCreate()}}},[_vm._v(" Save ")])]},proxy:true}])}),_c('ManageCategory',{attrs:{"dialog":_vm.manageCategoryDialog,"category":_vm.groupList},on:{"close-dialog":function($event){_vm.manageCategoryDialog = false},"success":_vm.getCategories}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }